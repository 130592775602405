<template>
  <div class="review-bg">
    <Navbar />
    <div class="review-body">
      <TopBar />
      <div class="filter-bar boxarea topic-filter">
        <SelectLayer />
      </div>
      <section class="competition1">
        <div class="boxarea">
          <p>{{ prodNameA_source }}</p>
          <p>{{ prodNameA }}</p>
          <span>{{ totalA }} {{ $t("reviews") }}</span>
          <Simple-Donut :series="competitorA" />
          <div class="confidence">
            <h2>{{ $t("review_credibility") }}</h2>
            <el-progress
              :text-inside="true"
              :stroke-width="20"
              :percentage="credibilityA"
              :format="format"
            ></el-progress>
            <p>{{ getScore(credibilityA) }}</p>
          </div>
          <button @click="handleReviews(0, prodNameA)">
            <i v-if="loading == 0" class="el-icon-loading"></i>
            <i v-else class="el-icon-document"></i>
          </button>
        </div>
        <div class="boxarea">
          <p>{{ prodNameB_source }}</p>
          <p>{{ prodNameB }}</p>
          <span>{{ totalB }} {{ $t("reviews") }}</span>
          <Simple-Donut :series="competitorB" />
          <div class="confidence">
            <h2>{{ $t("review_credibility") }}</h2>
            <el-progress
              :text-inside="true"
              :stroke-width="20"
              :percentage="credibilityB"
              :format="format"
            ></el-progress>
            <p>{{ getScore(credibilityB) }}</p>
          </div>
          <button @click="handleReviews(1, prodNameB)">
            <i v-if="loading == 1" class="el-icon-loading"></i>
            <i v-else class="el-icon-document"></i>
          </button>
        </div>

        <div class="boxarea">
          <p>{{ prodNameC_source }}</p>
          <p>{{ prodNameC }}</p>
          <span>{{ totalC }} {{ $t("reviews") }}</span>
          <Simple-Donut :series="competitorC" />
          <div class="confidence">
            <h2>{{ $t("review_credibility") }}</h2>
            <el-progress
              :text-inside="true"
              :stroke-width="20"
              :percentage="credibilityC"
              :format="format"
            ></el-progress>
            <p>{{ getScore(credibilityC) }}</p>
          </div>
          <button @click="handleReviews(2, prodNameC)">
            <i v-if="loading == 2" class="el-icon-loading"></i>
            <i v-else class="el-icon-document"></i>
          </button>
        </div>
        <div class="boxarea">
          <p>{{ prodNameD_source }}</p>
          <p>{{ prodNameD }}</p>
          <span>{{ totalD }} {{ $t("reviews") }}</span>
          <Simple-Donut :series="competitorD" />
          <div class="confidence">
            <h2>{{ $t("review_credibility") }}</h2>
            <el-progress
              :text-inside="true"
              :stroke-width="20"
              :percentage="credibilityD"
              :format="format"
            ></el-progress>
            <p>{{ getScore(credibilityD) }}</p>
          </div>
          <button @click="handleReviews(3, prodNameD)">
            <i v-if="loading == 3" class="el-icon-loading"></i>
            <i v-else class="el-icon-document"></i>
          </button>
        </div>
      </section>
      <section class="competition2">
        <!-- <div class="boxarea">
          <div class="box-title">
            <h2>조립</h2>
            <h2>RATE</h2>
          </div>
          <div>
            <div class="confidence-progress">
              <p>Calvin_Klein</p>
              <el-progress :text-inside="true" :stroke-width="20" :percentage="80" color="#6B93CC"></el-progress>
              <span>A</span>
            </div>
            <div class="confidence-progress">
              <p>Chouyatou</p>
              <el-progress
                :text-inside="true"
                :stroke-width="20"
                :percentage="73"
                color="#76B9E2"
                status="success"
              ></el-progress>
              <span>B</span>
            </div>
            <div class="confidence-progress">
              <p>Geben</p>
              <el-progress
                :text-inside="true"
                :stroke-width="20"
                :percentage="59"
                color="#B8378F"
                status="warning"
              ></el-progress>
              <span>C+</span>
            </div>
            <div class="confidence-progress">
              <p>Swiss_Keira</p>
              <el-progress
                :text-inside="true"
                :stroke-width="20"
                :percentage="38"
                color="#98C93C"
                status="warning"
              ></el-progress>
              <span>D</span>
            </div>
          </div>
        </div>-->
        <div class="boxarea" v-for="(data, k) in productFactors" :key="k">
          <div class="box-title">
            <h2>
              {{
                data._id === 1
                  ? "General"
                  : data._id === 2
                  ? "Size"
                  : data._id === 3
                  ? "Material"
                  : data._id === 4
                  ? "Quality"
                  : data._id === 5
                  ? "Color"
                  : data._id === 6
                  ? "Price"
                  : data._id === 7
                  ? "Picture"
                  : data._id === 8
                  ? "Style"
                  : data._id === 9
                  ? "Brand"
                  : data._id === 10
                  ? "Shipping"
                  : data._id === 11
                  ? "Durability"
                  : data._id === 12
                  ? "Problem"
                  : null
              }}
            </h2>
            <h2>Positive Rate</h2>
          </div>
          <!-- <Horizontal-Bar /> -->
          <div v-for="(item, j) in data[prodNameA]" :key="j">
            <div v-if="data[prodNameA]" class="confidence-progress">
              <p>{{ item.product }}</p>
              <el-progress
                :text-inside="true"
                :stroke-width="20"
                :percentage="Math.round((item.total / item.sum) * 100)"
                color="#6B93CC"
              ></el-progress>
              <span>{{ Math.round((item.total / item.sum) * 100) }}% </span>
            </div>
          </div>
          <div v-for="(item, l) in data[prodNameB]" :key="l">
            <div class="confidence-progress">
              <p>{{ item.product }}</p>
              <el-progress
                :text-inside="true"
                :stroke-width="20"
                :percentage="Math.round((item.total / item.sum) * 100)"
                color="#76B9E2"
              ></el-progress>
              <span>{{ Math.round((item.total / item.sum) * 100) }}% </span>
            </div>
          </div>
          <div v-for="(item, m) in data[prodNameC]" :key="m">
            <div class="confidence-progress">
              <p>{{ item.product }}</p>
              <el-progress
                :text-inside="true"
                :stroke-width="20"
                :percentage="Math.round((item.total / item.sum) * 100)"
                color="#B8378F"
              ></el-progress>
              <span>{{ Math.round((item.total / item.sum) * 100) }}% </span>
            </div>
          </div>
          <div v-for="(item, n) in data[prodNameD]" :key="n">
            <div class="confidence-progress">
              <p>{{ item.product }}</p>
              <el-progress
                :text-inside="true"
                :stroke-width="20"
                :percentage="Math.round((item.total / item.sum) * 100)"
                color="#98C93C"
              ></el-progress>
              <span>{{ Math.round((item.total / item.sum) * 100) }}% </span>
            </div>
          </div>
        </div>
      </section>
      <transition name="fade">
        <div class="dim" v-if="modalVisible">
          <ReviewDetail2
            @close="close"
            :reviewList="reviewList"
            :name="productName"
          />
          <div class="dimbg" @click="close"></div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { format } from "@/mixins/format";
// import HorizontalBar from "@/components/charts/HorizontalBar2";
import SimpleDonut from "@/components/charts/SimpleDonut2";
import Navbar from "@/components/Navbar";
import TopBar from "@/components/dashboard/TopBar";
import SelectLayer from "@/components/dashboard/SelectLayer";
import ReviewDetail2 from "@/components/pop/ReviewDetail2";
import {
  fetchCompetitorSentimentPred,
  fetchCompetiteProdutFactor,
  fetchAsprectReviews,
} from "@/api/review";
export default {
  mixins: [format],
  components: { SimpleDonut, TopBar, Navbar, SelectLayer, ReviewDetail2 },

  data() {
    return {
      modalVisible: false,
      reviewList: [],
      score: "",
      since: "6", // default 기간 설정
      competitorA: [],
      competitorB: [],
      competitorC: [],
      competitorD: [],
      credibilityA: 0,
      credibilityB: 0,
      credibilityC: 0,
      credibilityD: 0,
      totalA: 0,
      totalB: 0,
      totalC: 0,
      totalD: 0,
      prodNameA: "",
      prodNameB: "",
      prodNameC: "",
      prodNameD: "",
      prodNameA_source: "",
      prodNameB_source: "",
      prodNameC_source: "",
      prodNameD_source: "",
      credibleTotal_A: 0,
      credibleTotal_B: 0,
      credibleTotal_C: 0,
      credibleTotal_D: 0,
      listA: [],
      listB: [],
      listC: [],
      listD: [],
      isTopic: "",
      productFactors: [],
      productFactorsForTotal: [],
      loading: null,
      productName: "",
    };
  },
  created() {
    this.$store.dispatch("DASHBOARD_MENU", 4);
  },
  mounted() {
    this.getCompetitorSentimentPred();
  },
  computed: {
    ...mapState([
      "navVisible",
      "footerVisible",
      "url",
      "asin",
      "term",
      "type",
      "competitorList",
    ]),
  },
  watch: {
    asin: function () {
      this.getCompetitorSentimentPred();
      this.getCompetitiProductFactor();
    },
    url: function () {
      this.getCompetitorSentimentPred();
      this.getCompetitiProductFactor();
    },
    term: function () {
      this.getCompetitorSentimentPred();
      this.getCompetitiProductFactor();
      this.$forceUpdate();
    },
  },
  methods: {
    close() {
      this.modalVisible = false;
    },

    handleReviews(i, productName) {
      this.productName = productName;
      let params = {
        productName: productName,
        asin: this.asin,
        url: this.url,
        term: this.term,
        aspectIndex: "",
        ABSA_predict: "",
        type: this.type,
      };
      this.loading = i;
      // let params;
      // if (i == 0) {
      //   params = {
      //     productName: productName,
      //     asin: this.asin,
      //     url: this.url,
      //     term: this.term,
      //     aspectIndex: "",
      //     ABSA_predict: "",
      //     type: this.type
      //   };
      // } else {
      //   if (this.competitorList[i - 1] == undefined) {
      //     this.loading = null;
      //     return alert("데이터가 없습니다.");
      //   }
      //   params = {
      //     asin: this.competitorList[i - 1].asin
      //       ? this.competitorList[i - 1].asin
      //       : "",
      //     url: this.competitorList[i - 1].url
      //       ? this.competitorList[i - 1].url
      //       : "",
      //     term: this.competitorList[i - 1].term
      //       ? this.competitorList[i - 1].term
      //       : "",
      //     aspectIndex: "",
      //     ABSA_predict: "",
      //     type: this.type
      //   };
      // }
      fetchAsprectReviews(params).then((res) => {
        if (res.data.status == 200) {
          window.console.log(res.data.data);
          this.reviewList = res.data.data;
          this.modalVisible = true;
        } else {
          alert(res.data.message);
        }
        this.loading = null;
        this.modalVisible = true;
      });
    },
    handleTopic() {
      this.isTopic = !this.isTopic;
    },
    getCompetitiProductFactor() {
      let params = {
        asin: this.asin,
        url: this.url,
        term: this.term,
      };

      fetchCompetiteProdutFactor(params).then((res) => {
        if (res.data.status == 200) {
          this.productFactors = res.data.data;
          this.productFactorsForTotal = res.data.totalData;
          this.productFactorsForTotal.forEach((data) => {
            this.productFactors.forEach((item) => {
              // window.console.log("this.productFactors item", item);
              if (data._id == item._id) {
                item[this.prodNameA]
                  ? (item[this.prodNameA][0].sum = data[this.prodNameA]
                      ? data[this.prodNameA][0].total
                      : 0)
                  : 0;
                item[this.prodNameB]
                  ? (item[this.prodNameB][0].sum =
                      data[this.prodNameB][0].total)
                  : 0;
                item[this.prodNameC]
                  ? (item[this.prodNameC][0].sum =
                      data[this.prodNameC][0].total)
                  : 0;
                item[this.prodNameD]
                  ? (item[this.prodNameD][0].sum =
                      data[this.prodNameD][0].total)
                  : 0;
              }
            });
          });
        } else {
          return alert(res.data.message);
        }
      });
    },
    getCompetitorSentimentPred() {
      let params = {
        asin: this.asin,
        url: this.url,
        term: this.term,
      };

      fetchCompetitorSentimentPred(params).then((res) => {
        this.competitorA = [];
        this.competitorB = [];
        this.competitorC = [];
        this.competitorD = [];
        this.credibilityA = 0;
        this.credibilityB = 0;
        this.credibilityC = 0;
        this.credibilityD = 0;
        this.totalA = 0;
        this.totalB = 0;
        this.totalC = 0;
        this.totalD = 0;
        this.prodNameA = "";
        this.prodNameB = "";
        this.prodNameC = "";
        this.prodNameD = "";
        this.productA = [];
        this.productB = [];
        this.productC = [];
        this.productD = [];
        this.absaSumA = [];
        this.absaSumB = [];
        this.absaSumC = [];
        this.absaSumD = [];
        this.prodNameA_source = "";
        this.prodNameB_source = "";
        this.prodNameC_source = "";
        this.prodNameD_source = "";
        this.listA = [];
        this.listB = [];
        this.listC = [];
        this.listD = [];

        let prodNames = res.data.credible.map((item) => {
          return item._id.prodName;
        });
        this.prodNameA = prodNames[0];
        this.prodNameB = prodNames[1];
        this.prodNameC = prodNames[2];
        this.prodNameD = prodNames[3];

        window.console.log(
          "this.res.data.sentimentList ",
          res.data.sentimentList
        );
        res.data.sentimentList.map((item) => {
          if (item._id.prodName == this.prodNameA) {
            this.prodNameA_source = item.sentiment_preds[0].source;
            this.listA = item
              ? item.sentiment_preds.sort((a, b) => {
                  return b["preds"] - a["preds"];
                })
              : [];
          } else if (item._id.prodName == this.prodNameB) {
            this.prodNameB_source = item.sentiment_preds[0].source;
            this.listB = item
              ? item.sentiment_preds.sort((a, b) => {
                  return b["preds"] - a["preds"];
                })
              : [];
          } else if (item._id.prodName == this.prodNameC) {
            this.prodNameC_source = item.sentiment_preds[0].source;
            this.listC = item
              ? item.sentiment_preds.sort((a, b) => {
                  return b["preds"] - a["preds"];
                })
              : [];
          } else if (item._id.prodName == this.prodNameD) {
            this.prodNameD_source = item.sentiment_preds[0].source;
            this.listD = item
              ? item.sentiment_preds.sort((a, b) => {
                  return b["preds"] - a["preds"];
                })
              : [];
          }
        });
        // this.prodNameA_source = res.data.sentimentList[0]
        //   ? res.data.sentimentList[0].sentiment_preds[0].source
        //   : "";
        // this.prodNameB_source = res.data.sentimentList[1]
        //   ? res.data.sentimentList[1].sentiment_preds[0].source
        //   : "";
        // this.prodNameC_source = res.data.sentimentList[2]
        //   ? res.data.sentimentList[2].sentiment_preds[0].source
        //   : "";
        // this.prodNameD_source = res.data.sentimentList[3]
        //   ? res.data.sentimentList[3].sentiment_preds[0].source
        //   : "";

        // let listA = res.data.sentimentList[0]
        //   ? res.data.sentimentList[0].sentiment_preds.sort((a, b) => {
        //       return b["preds"] - a["preds"];
        //     })
        //   : [];
        // window.console.log("listA ", listA);
        // let listB = res.data.sentimentList[1]
        //   ? res.data.sentimentList[1].sentiment_preds.sort((a, b) => {
        //       return b["preds"] - a["preds"];
        //     })
        //   : [];
        // window.console.log("listB ", listB);
        // let listC = res.data.sentimentList[2]
        //   ? res.data.sentimentList[2].sentiment_preds.sort((a, b) => {
        //       return b["preds"] - a["preds"];
        //     })
        //   : [];
        // window.console.log("listC ", listC);
        // let listD = res.data.sentimentList[3]
        //   ? res.data.sentimentList[3].sentiment_preds.sort((a, b) => {
        //       return b["preds"] - a["preds"];
        //     })
        //   : [];

        let Aneu = 0;
        let Apos = 0;
        let Aneg = 0;

        this.listA.forEach((data) => {
          if (data.preds == 0) {
            Aneu = data.total;
          } else if (data.preds == 1) {
            Apos = data.total;
          } else if (data.preds == 2) {
            Aneg = data.total;
          }
        });
        this.competitorA = [Apos, Aneu, Aneg];

        let Bneu = 0;
        let Bpos = 0;
        let Bneg = 0;

        this.listB.forEach((data) => {
          if (data.preds == 0) {
            Bneu = data.total;
          } else if (data.preds == 1) {
            Bpos = data.total;
          } else if (data.preds == 2) {
            Bneg = data.total;
          }
        });
        this.competitorB = [Bpos, Bneu, Bneg];

        let Cneu = 0;
        let Cpos = 0;
        let Cneg = 0;

        this.listC.forEach((data) => {
          if (data.preds == 0) {
            Cneu = data.total;
          } else if (data.preds == 1) {
            Cpos = data.total;
          } else if (data.preds == 2) {
            Cneg = data.total;
          }
        });
        this.competitorC = [Cpos, Cneu, Cneg];

        let Dneu = 0;
        let Dpos = 0;
        let Dneg = 0;

        this.listD.forEach((data) => {
          if (data.preds == 0) {
            Dneu = data.total;
          } else if (data.preds == 1) {
            Dpos = data.total;
          } else if (data.preds == 2) {
            Dneg = data.total;
          }
        });
        this.competitorD = [Dpos, Dneu, Dneg];

        this.totalA = this.sumArray(this.competitorA);
        this.totalB = this.sumArray(this.competitorB);
        this.totalC = this.sumArray(this.competitorC);
        this.totalD = this.sumArray(this.competitorD);

        window.console.log("prodNames", prodNames);
        window.console.log("res.data.credible", res.data.credible);
        // let credibleTotal = res.data.credible.map((item) => {
        //   return item.total;
        // });
        let credibleTotal = res.data.credible;

        window.console.log("credibleTotal", credibleTotal);
        window.console.log("this.prodNameA:", this.prodNameA);
        credibleTotal.map((item) => {
          window.console.log("item:", item);
          switch (item._id.prodName) {
            case this.prodNameA:
              this.credibleTotal_A = item.total;
              break;
            case this.prodNameB:
              this.credibleTotal_B = item.total;
              break;
            case this.prodNameC:
              this.credibleTotal_C = item.total;
              break;
            case this.prodNameD:
              this.credibleTotal_D = item.total;
              break;
          }
        });
        window.console.log("credibleTotal_A", this.credibleTotal_A);
        window.console.log("credibleTotal_B", this.credibleTotal_B);
        window.console.log("credibleTotal_C", this.credibleTotal_C);
        window.console.log("totalA", this.totalA);
        window.console.log("totalB", this.totalB);
        window.console.log("totalC", this.totalC);
        this.credibilityA = this.totalA
          ? Math.floor((this.credibleTotal_A / this.totalA) * 100)
          : 0;
        this.credibilityB = this.totalB
          ? Math.floor((this.credibleTotal_B / this.totalB) * 100)
          : 0;
        this.credibilityC = this.totalC
          ? Math.floor((this.credibleTotal_C / this.totalC) * 100)
          : 0;
        this.credibilityD = this.totalD
          ? Math.floor((this.credibleTotal_D / this.totalD) * 100)
          : 0;

        this.getCompetitiProductFactor();
      });
    },
    sumArray(arr) {
      let result = 0;
      for (let i = 0; i < arr.length; i++) {
        result += arr[i];
      }
      return result;
    },
    format(percentage) {
      if (percentage === 100) {
        this.score = "A+";
      } else {
        this.score = "C+";
      }
      return `${percentage}%`;
    },
  },
  beforeDestroy() {
    this.$store.dispatch("SET_ASIN", "");
    this.$store.dispatch("SET_URL", "");
    this.$store.dispatch("VIEW_TERM", "");
  },
};
</script>
<style>
.el-progress-bar {
  width: 80%;
  margin-top: 15px;
}
</style>
